import CryptoJS from "crypto-js";

function generateKey() {
  const key = CryptoJS.enc.Base64.parse(`${process.env.REACT_APP_SECRET_KEY}`);
  const currentDate = new Date();
  const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getUTCDate().toString().padStart(2, "0");
  const message = `${month}${process.env.REACT_APP_SECRET_MSG}${day}`;

  const hmac = CryptoJS.HmacSHA256(message, key);
  const signature = hmac.toString(CryptoJS.enc.Hex);

  return signature;
}

export default generateKey;
